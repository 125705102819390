@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}